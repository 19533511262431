(function ($) {
  Drupal.behaviors.welcome15_offer_signup_with_image_v1 = {
    attach: function () {
      $(document).on('cbox_complete', function () {
        var $cbox = $('#colorbox.welcome15-overlay');
        var $element = $cbox.find('.welcome15-offer-signup-with-image');

        if ($element.length > 0) {
          $cbox.addClass('welcome15-overlay-with-image');
          $.colorbox.resize({ width: '845px' });
        } else {
          if ($cbox.length > 0) {
            $cbox.removeClass('welcome15-overlay-with-image');
            $.colorbox.resize({ width: '590px' });
          }
        }
      });
      $(document).on('cbox_closed', function () {
        var $cbox = $('#colorbox.welcome15-overlay');

        if ($cbox.length > 0) {
          $cbox.removeClass('welcome15-overlay-with-image');
        }
      });
    }
  };
})(jQuery);
